/* src/components/Projects.css */
.projects-section {
  text-align: center;
  padding: 20px;
}

.projects-section h2 {
  margin-bottom: 20px;
  font-size: 2.5rem;
  text-align: center;
}

.projects-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.projects-grid .project-card {
  flex: 1 1 200px; /* Ensure all project cards take up an equal amount of space */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
  transition: background-color 0.3s ease;
  width: fit-content;
}

.projects-grid .project-card:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
}

.projects-grid .project-card .project-card-content {
  color: #fff; /* Ensure text is readable on dark background */
}

.projects-grid .project-card h3 {
  color: #fff; /* Ensure title is readable */
}

.projects-grid .project-card p {
  color: #ddd; /* Slightly softer color for description */
}

/* If you're using a dark mode, you might want to adjust these colors */
body.dark-mode .projects-grid .project-card {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent light background for dark mode */
}

body.dark-mode .projects-grid .project-card:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Lighter on hover in dark mode */
}

body.dark-mode .projects-grid .project-card .project-card-content,
body.dark-mode .projects-grid .project-card h3,
body.dark-mode .projects-grid .project-card p {
  color: #fff; /* Ensure text is readable in dark mode */
}



