.footer {
    background-color: transparent; /* No background */
    padding: 10px;
    text-align: left; /* Align to the left */
    font-size: 0.8em;
    color: #666;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  
  .footer p {
    margin: 0;
    display: flex;
    justify-content: flex-start; /* Align content to the start */
    align-items: center;
  }
  
  .footer-logo {
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }
  
  /* Dark mode styles */
  body.dark-mode .footer {
    color: #fff; /* White text in dark mode */
  }
  
  
  
  