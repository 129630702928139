.contact {
  padding: 40px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.contact h2 {
  margin-bottom: 20px;
  font-size: 2.5rem;
}

.contact form {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  height: fit-content;
  min-height: 100px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 768px) {
  .contact {
    padding: 20px 10px;
  }

  .contact h2 {
    font-size: 2rem;
  }

  .contact form {
    width: 95%;
  }

  .form-group input,
  .form-group textarea {
    font-size: 16px; /* Prevents zoom on input focus on iOS */
  }

  button {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .contact h2 {
    font-size: 1.8rem;
  }

  .form-group label {
    font-size: 0.9rem;
  }
}