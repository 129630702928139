.about {
  padding: 60px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

.about h2 {
  margin-bottom: 30px;
  font-size: 3rem;
  color: #333;
  font-weight: bold;
}

.about p {
  max-width: 100%;
  line-height: 1.8;
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #444;
  font-weight: 500; /* Slightly bolder than normal */
}

.about a {
  font-size: 1.2rem;
  color: #0066cc;
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: bold;
}

.about a:hover {
  color: #004499;
  text-decoration: underline;
}

/* Dark mode styles */
body.dark-mode .about h2 {
  color: #fff;
}

body.dark-mode .about p {
  color: #ddd;
}

body.dark-mode .about a {
  color: #66b3ff;
}

body.dark-mode .about a:hover {
  color: #99ccff;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .about {
    padding: 40px 15px;
  }

  .about h2 {
    font-size: 2.5rem;
  }

  .about p, .about a {
    font-size: 1.1rem;
  }
}
