.floating-toggles {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.menu-button, .icon-button {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.menu-button:hover, .icon-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

body.dark-mode .menu-button, body.dark-mode .icon-button {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

body.dark-mode .menu-button:hover, body.dark-mode .icon-button:hover {
  background: rgba(0, 0, 0, 0.3);
}

.menu-popup {
  position: absolute;
  top: 55px;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-width: 150px;
  backdrop-filter: blur(10px);
}

body.dark-mode .menu-popup {
  background: rgba(0, 0, 0, 0.2);
}

.MuiListItem-root {
  border-radius: 8px;
  margin-bottom: 5px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.MuiListItem-root:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

body.dark-mode .MuiListItem-root:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.MuiListItemText-primary {
  color: #070707;
  font-size: 1rem;
  text-shadow: none;
}

body.dark-mode .MuiListItemText-primary {
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.MuiListItem-root .MuiListItemText-root {
  transition: color 0.2s ease;
}

.MuiListItem-root:hover .MuiListItemText-root {
  color: #000;
}

body.dark-mode .MuiListItem-root:hover .MuiListItemText-root {
  color: #fff;
}