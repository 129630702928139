.home-lab-section {
  padding: 60px 20px;
  text-align: center;
}

.home-lab-summary {
  font-size: 2.3em; /* Increased from 2.1em */
  margin-bottom: 100px;
  margin-top: 100px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  color: #666; /* Summary color for light mode */
}

.home-lab-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: rgba(255, 255, 255, 0.85);
  margin-bottom: 20px;
  width: 80%;
  margin: 20px auto;
  z-index: 1;
  opacity: 0;
  transform: translateY(100px); /* Increased from 20px to 100px */
  transition: opacity 1.2s ease-out, transform 1.2s ease-out; /* Increased duration and added easing */
  position: relative;
  margin: 45px auto;
}
.home-lab-card.visible {
  opacity: 1;
  transform: translateY(0);
}

.home-lab-card:hover, .home-lab-card:focus {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.home-lab-image {
  width: 450px;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  margin: 10px;
  border-radius: 8px;
}

.home-lab-content {
  padding: 20px;
  text-align: left;
}

.home-lab-content h3 {
  margin: 16px 0 8px;
  font-size: 1.7em; /* Increased from 1.5em */
}

.home-lab-section h2 {
  font-size: 3.2em; /* Increased from 3em */
  margin-bottom: 20px;
}

.home-lab-content p {
  margin: 0 0 16px;
  font-size: 1.3em; /* Increased from 1.2em */
}

.home-lab-content ul {
  padding-left: 20px;
  margin: 0 0 16px;
  font-size: 1.3em; /* Increased from 1.2em */
}

.home-lab-content ul ul {
  font-size: 0.95em; /* Slightly increased from 0.9em */
  margin-bottom: 0;
}

.home-lab-content li {
  margin-bottom: 8px;
}

.home-lab-content ul ul li {
  margin-bottom: 4px;
}

.home-lab-content a {
  color: #007bff;
  text-decoration: none;
}

.home-lab-content a:hover {
  text-decoration: underline;
}

/* Dark mode styles */
body.dark-mode .home-lab-summary {
  color: #ddd;
}

body.dark-mode .home-lab-card {
  background-color: rgba(51, 51, 51, 0.316);
  color: #fff;
}

body.dark-mode .home-lab-content a {
  color: #4ca1af;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .home-lab-image {
    width: 300px;
    max-height: 190px;
  }
  .home-lab-summary {
    font-size: 1.7em; /* Increased from 1.5em */
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .home-lab-section h2 {
    font-size: 2.2em; /* Increased from 2em */
    margin-bottom: 10px;
  }

  .home-lab-content h3 {
    font-size: 1.4em; /* Increased from 1.2em */
  }

  .home-lab-content p,
  .home-lab-content ul {
    font-size: 1.1em; /* Increased from 1em */
  }

  .home-lab-card {
    width: 90%;
    margin: 30px auto;
  }
}

@media (max-width: 480px) {
  .home-lab-image {
    width: 200px;
    max-height: 180px;
  }
  .home-lab-summary {
    font-size: 1.4em; /* Increased from 1.2em */
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .home-lab-section h2 {
    font-size: 2em; /* Increased from 1.8em */
    margin-bottom: 10px;
  }

  .home-lab-content h3 {
    font-size: 1.4em; /* Increased from 1em */
  }

  .home-lab-content p,
  .home-lab-content ul {
    font-size: 1.3em; /* Increased from 0.9em */
  }

  .home-lab-card {
    width: 95%;
    margin: 30px auto;
    max-width: fit-content;
  }
}