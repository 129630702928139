html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.6;
  background-color: #ece9e6;
  transition: background-color 0.5s, color 0.5s;
}

body.dark-mode {
  background-color: #2c3e50;
  color: #ffffff;
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  background-color: #ece9e6;
  transition: background-color 0.5s;
}

body.dark-mode .background-overlay {
  background-color: #197684;
}

.navbar {
  background: rgba(255, 255, 255, 0.9) !important;
  box-shadow: none;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

body.dark-mode .navbar {
  background: rgba(0, 0, 0, 0.9) !important;
}

.navbar-title {
  flex-grow: 1;
  color: #333;
}

body.dark-mode .navbar-title {
  color: #ffffff;
}

.MuiButton-root {
  color: #333 !important;
}

body.dark-mode .MuiButton-root {
  color: #ffffff !important;
}

.MuiButton-root:hover {
  background: rgba(0, 0, 0, 0.1);
}

body.dark-mode .MuiButton-root:hover {
  background: rgba(255, 255, 255, 0.1);
}

.icon-button {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 8px;
}

.icon-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

body.dark-mode .icon-button {
  background-color: rgba(255, 255, 255, 0.1);
}

body.dark-mode .icon-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

p, li {
  font-weight: 300;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.nav-link, .MuiListItemText-root {
  font-size: 1.2rem;
}

/* Particles Background */
#particles-js {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

/* iOS-specific styles */
@supports (-webkit-touch-callout: none) {
  .background-overlay {
    position: absolute;
    min-height: 100vh;
  }
  
  #particles-js {
    position: absolute;
    min-height: 100vh;
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .background-overlay {
    background-color: #ece9e6;
  }

  body.dark-mode .background-overlay {
    background-color: #197684;
  }
}