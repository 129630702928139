/* src/components/ProjectCard.css */

.project-card {
  display: block;
  text-decoration: none;
  color: inherit;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  flex: 1 1 200px;
  display: flex;
  flex-direction: column;
}

.project-card-content {
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 170px; /* Adjust this value as needed */
}

.project-card-image {
  max-width: 150px;
  max-height: 150px;
  width: auto;
  height: auto;
  object-fit: contain; /* This will maintain the aspect ratio */
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-card h3 {
  margin: 16px 0 8px;
  flex-grow: 0;
}

.project-card p {
  margin: 0 0 16px;
  flex-grow: 1;
}