body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.home-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.headshot-container {
  position: absolute;
  top: 20px;
  left: 20px;
}

.headshot {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.home {
  text-align: center;
  width: 100%;
}

.home-container {
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 20px;
}

.typing-effect {
  font-size: 2.5rem;
  margin-bottom: 20px;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  text-decoration: none;
  color: inherit;
  padding: 8px 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: inline-block;
  font-size: 1rem;
}

nav ul li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

body.dark-mode nav ul li a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* New animation styles */
.home-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.home-section.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Media Queries for Mobile Fix */
@media (max-width: 768px) {
  .home-container {
    max-width: 100%;
  }

  .typing-effect {
    font-size: 2.5rem;
  }

  nav ul {
    gap: 8px;
  }

  nav ul li a {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .home-wrapper {
    padding: 10px;
  }

  .home-container {
    padding: 0 10px;
  }

  .typing-effect {
    font-size: 2rem;
  }

  .headshot {
    width: 80px;
    height: 80px;
  }

  nav ul {
    gap: 5px;
  }

  nav ul li a {
    padding: 5px 10px;
    font-size: 0.85rem;
  }
}